.user {
  flex: 4;
  padding: 10px;
}
.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}
.userContainer {
  display: flex;
  /* margin-top: 20px; */
}
.userShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}
.userUpdate {
  flex: 2;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}
.userShowTop {
  display: flex;
  align-items: center;
}
.userShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.userShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.userShowUsername {
  font-weight: 600;
}
.userShowUserTitle {
  font-weight: 300;
}
.userShowBottom{
    margin-top: 20px;
}
.userShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}
.userShowInfo{
    display: flex;
    align-items: center;
    margin: 5px 0px;
    color: #444;
}
.userShowInfoUser{
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    margin: 5px 0px;
    color: #444;
}
.userShowInfoProduct{
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    color: #444;
}
.userShowIcon{
    font-size: 16px !important;
}
.userShowInfoTitle{
    margin-left: 10px;
}
.userShowInfoTitle > strong{
    margin-left: 20px;
}
.userShowInfoTitle > div{
    margin-left: 20px;
}
.imprimirPDF {
  width: 120px;
  border: none;
  padding: 10px 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
.main__orden {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1,  1fr);
}
.table {
  width: 80%;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 1px solid #000;
  border-collapse: collapse;
}
.th {
  background-color: #eee;
}
.th, .td {
  /* width: 25%; */
  font-size: 14px;
  text-align: left;
  vertical-align: top;
  border: 1px solid #000;
  padding: 0.3em;
  caption-side: bottom;
  text-align: center;
}
@media (max-width: 768px) {
  .userTitleContainer {
    padding: 0 10px;
  }
  .userShow {
    padding: 0;
  }
  .userShowInfoTitle{
    margin-left: 0px;
  }
  .main__orden {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .flex {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}