:root {
  --swiper-theme-color: #10c98f;
  --swiper-navigation-size: 28px;
}
.swiper {
  width: 100%;
  /* height: 600px; */
  margin: 69px 0 30px;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 16px;
  background: transparent;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next, .swiper-button-prev {
  /* color: rgba(128, 128, 128, 0.459); */
  /* color: #3483fa; */
  color: #10c98f;
  font-weight: bolder;
  background-color: #fff;
  padding: 10px 15px;
  margin: -35px 10px 0;
  border-radius: 50%;
  /* box-shadow: 0 0 0 2px #eee; */
  box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.35);
  -webkit-tap-highlight-color: rgba(0,0,0,0); 
}
/* .swiper-button-next, .swiper-button-prev:hover {
  box-shadow: 0 0 5px 3px #d1cdcd;
} */

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 16px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}


@media only screen and (max-width: 485px) {
  .swiper {
    width: 100%;
    height: 300px;
    margin: 68px 0 20px;
  }
  .swiper-button-next, .swiper-button-prev {
    /* padding: 15px 18px; */
    margin: -38px -7px 0 -7px;
    box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.35);
  }
  /* :root {
    --swiper-navigation-size: 25px;
  } */
  /* .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
  } */
  .swiper img {
    position: absolute;
    top: 0;
    height: 300px;
    object-fit: cover;
  }
}
