.dropbtn {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.5px ;
  border: none;
  background-color: transparent;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 50px;
  background-color: #f9f9f9;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
  font-size: 15px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #eee}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropbtn:hover {
  color: #10C98F;
}

.dropdown2 {
  display: none;
}

@media (max-width: 992px) {
  .dropbtn {
    color: #888888;
  }
  .dropdown {
    display: none;
  }
  .dropdown2 {
    display: block;
  }
  .dropbtn2 {
    text-decoration: none;
    color: #888888;
    display: flex;
    padding: 10px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bolder;
    border: none;
    background-color: transparent;
    list-style: none;
  }
  .dropdown2-content {
    border: none;
    background-color: transparent;
    font-weight: bolder;
  }
  .dropdown2-content a {
    color: #707070;
    padding: 6px 10px;
    text-decoration: none;
    display: block;
    font-size: 15px;
    margin-left: 20px;
  } 
}