.productForm {
  display: flex;
  justify-content: space-between;
}
.productFormLeft {
  display: flex;
  flex-direction: column;
}
.productFormRight{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.productUpload{
  display: flex;
  align-items: center;
}
.productUploadImg{
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}
@media (max-width: 768px){
  .productForm {
    flex-direction: column;
  }
  .productFormLeft {
    order: 2;
  }
  .productFormRight {
    margin-top: 10px;
    order: 1;
  }
}